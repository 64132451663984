import React from 'react';

import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.footerContainer}>
    <div>Copyright &copy; 2022 - Shockey Mini Storage, LP - All rights reserved.</div>
  </div>
);

export default Footer;
